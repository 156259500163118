import React, { useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Typography from "@mui/material/Typography";
import { RootState } from "../../../app/store";
import {
  FDocenteteInterface,
  FDocenteteLigneInterface,
} from "../../../interfaces/FDocenteteInterface";
import { FDOCENTETES_URL } from "../../../utils/UrlsUtils";
import { set } from "../../../app/globalSlice";
import { toastr } from "react-redux-toastr";
import { GET, POST } from "../../../utils/MethodUtils";
import { requestApi } from "../../../helpers/RequestApi";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import SearchComponent from "../SearchComponent";
import CopyClipboardComponent from "../CopyClipboardComponent";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { PRODUCT_PAGE } from "../../../utils/RouteUtils";
import { getUrlFArticleImage } from "../../../helpers/FileHelper";
import { IMAGE_SIZE_LOGO, IMAGE_SIZE_SMALL } from "../../../utils/FileUtils";
import HideImageIcon from "@mui/icons-material/HideImage";
import { FDOCENETETE_COLORS } from "../../../utils/FDoceneteteUtils";
import { LoadingButton } from "@mui/lab";
import { InputInterface } from "../../../interfaces/InputInterface";
import { TextField, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InfoIcon from "@mui/icons-material/Info";
import getErrorApi from "../../../helpers/GetErrorApi";

interface State {
  doPiece: string | undefined;
}

interface State2 {
  fDocentete: FDocenteteInterface;
  fDocligne: FDocenteteLigneInterface;
  showBottom: boolean;
  enteringInvoice: EnteringInvoiceInterface | undefined;
}

interface EnteringInvoiceInterface {
  arRef: string;
  sorecop: number;
  pAchat: number;
  ecotaxe: number;
  cbModification: string;
  doPiece: string;
}

interface FDocenteteLigneRefInterface {
  fDocligne: FDocenteteLigneInterface;
  ref: any;
}

interface FormState {
  pAchat: InputInterface;
  ecotaxe: InputInterface;
  sorecop: InputInterface;
  dlPrixunitaire: InputInterface;
}

const EnteringInvoiceFDocligneComponent = React.memo(
  React.forwardRef(
    ({ fDocentete, fDocligne, enteringInvoice, showBottom }: State2, ref) => {
      const theme = useTheme();
      const { t, i18n } = useTranslation();
      const afReffourniss =
        fDocligne.fArticle?.fArtfourniss?.find(
          // @ts-ignore
          (f) => f.ctNum.ctNum === fDocentete.doTiers?.ctNum
        )?.afReffourniss ?? "";
      const getDefaultValue = React.useCallback((): FormState => {
        return {
          pAchat: {
            value: Number(fDocligne.pAchat),
            error: "",
          },
          ecotaxe: {
            value: Number(fDocligne.ecotaxe),
            error: "",
          },
          sorecop: {
            value: Number(fDocligne.sorecop),
            error: "",
          },
          dlPrixunitaire: {
            value: Number(fDocligne.dlPrixunitaire),
            error: "",
          },
        };
      }, [
        fDocligne.dlPrixunitaire,
        fDocligne.ecotaxe,
        fDocligne.pAchat,
        fDocligne.sorecop,
      ]);
      const [values, setValues] = React.useState<FormState>(getDefaultValue());

      let color = "black";
      if (
        fDocentete?.doType &&
        FDOCENETETE_COLORS.hasOwnProperty(fDocentete.doType)
      ) {
        color = FDOCENETETE_COLORS[fDocentete?.doType].main;
      }

      const handleChange = React.useCallback(
        (prop: keyof FormState) =>
          (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues((v) => {
              return {
                ...v,
                [prop]: { ...v[prop], value: event.target.value, error: "" },
              };
            });
          },
        []
      );

      useImperativeHandle(ref, () => ({
        copyPuht() {
          setValues((v) => {
            v.pAchat.value = Number(fDocligne.dlPrixunitaire);
            v.pAchat.error = "";
            return { ...v };
          });
        },
        copyEcotaxe() {
          setValues((v) => {
            v.ecotaxe.value = Number(enteringInvoice?.ecotaxe ?? 0);
            v.ecotaxe.error = "";
            return { ...v };
          });
        },
        copySorecop() {
          setValues((v) => {
            v.sorecop.value = Number(enteringInvoice?.sorecop ?? 0);
            v.sorecop.error = "";
            return { ...v };
          });
        },
        calculate() {
          setValues((v) => {
            v.dlPrixunitaire.value =
              Number(v.pAchat.value ?? 0) +
              Number(v.sorecop.value ?? 0) +
              Number(v.ecotaxe.value ?? 0);
            v.dlPrixunitaire.error = "";
            return { ...v };
          });
        },
        getValue() {
          return {
            dlNo: fDocligne.dlNo,
            pAchat: Number(values.pAchat.value) ?? 0,
            ecotaxe: Number(values.ecotaxe.value) ?? 0,
            sorecop: Number(values.sorecop.value) ?? 0,
            dlPrixunitaire: Number(values.dlPrixunitaire.value) ?? 0,
          };
        },
      }));

      return (
        <TableRow>
          {fDocligne.fArticle ? (
            <>
              <TableCell
                sx={{
                  paddingY: 0,
                  borderLeft: "2px " + color + " solid",
                  ...(showBottom && {
                    borderBottom: "2px " + color + " dashed",
                  }),
                }}
              >
                {fDocligne.fArticle?.fArticleProp?.images !== null &&
                fDocligne.fArticle?.fArticleProp?.images !== undefined &&
                fDocligne.fArticle.fArticleProp?.images.length > 0 ? (
                  <Link to={PRODUCT_PAGE + "/" + fDocligne.fArticle.arRef}>
                    <img
                      src={
                        (process.env.REACT_APP_API_URL ?? "") +
                        getUrlFArticleImage(
                          fDocligne.fArticle.arRef,
                          fDocligne.fArticle.fArticleProp?.images[0],
                          IMAGE_SIZE_SMALL
                        )
                      }
                      style={{
                        margin: "auto",
                        width: IMAGE_SIZE_SMALL / 2,
                      }}
                      alt={
                        "[" +
                        fDocligne.fArticle?.arRef +
                        "] " +
                        fDocligne.fArticle?.arDesign
                      }
                      loading="lazy"
                    />
                  </Link>
                ) : (
                  <HideImageIcon
                    sx={{
                      width: IMAGE_SIZE_LOGO,
                      height: IMAGE_SIZE_LOGO,
                    }}
                  />
                )}
              </TableCell>
              <TableCell
                sx={{
                  ...(showBottom && {
                    borderBottom: "2px " + color + " dashed",
                  }),
                }}
              >
                <CopyClipboardComponent
                  className="RobotoMono"
                  component="span"
                  sx={{
                    color: theme.palette.success.main,
                    wordBreak: "keep-all",
                  }}
                  text={fDocligne.fArticle?.arRef}
                />
              </TableCell>
              <TableCell
                sx={{
                  ...(showBottom && {
                    borderBottom: "2px " + color + " dashed",
                  }),
                }}
              >
                {fDocligne.fArticle?.arDesign}
              </TableCell>
              <TableCell
                sx={{
                  ...(showBottom && {
                    borderBottom: "2px " + color + " dashed",
                  }),
                }}
              >
                <CopyClipboardComponent
                  className="RobotoMono"
                  component="span"
                  sx={{
                    wordBreak: "keep-all",
                  }}
                  text={afReffourniss}
                />
              </TableCell>
              <TableCell
                sx={{
                  ...(showBottom && {
                    borderBottom: "2px " + color + " dashed",
                  }),
                }}
              >
                {Number(fDocligne.dlQte)}
              </TableCell>
              <TableCell
                sx={{
                  verticalAlign: "bottom",
                  ...(showBottom && {
                    borderBottom: "2px " + color + " dashed",
                  }),
                }}
              >
                {Number(fDocligne.pAchat) !==
                  Number(fDocligne.dlPrixunitaire) && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {Number(fDocligne.pAchat)}
                    <ArrowForwardIcon />
                    {Number(fDocligne.dlPrixunitaire)}
                  </Box>
                )}
                <TextField
                  autoComplete="off"
                  error={!!values.pAchat.error}
                  helperText={t(values.pAchat.error ?? "")}
                  sx={{ width: "100%" }}
                  type="text"
                  value={values.pAchat.value}
                  onChange={handleChange("pAchat")}
                />
              </TableCell>
              <TableCell
                sx={{
                  verticalAlign: "bottom",
                  ...(showBottom && {
                    borderBottom: "2px " + color + " dashed",
                  }),
                }}
              >
                {Number(fDocligne.ecotaxe) !==
                  Number(enteringInvoice?.ecotaxe ?? 0) && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {Number(fDocligne.ecotaxe)}
                    <ArrowForwardIcon />
                    {Number(enteringInvoice?.ecotaxe ?? 0)}
                    <Tooltip
                      title={
                        <ul>
                          <li>{enteringInvoice!.doPiece}</li>
                          <li>
                            {new Date(
                              enteringInvoice!.cbModification
                            ).toLocaleDateString(i18n.language, {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                              timeZone: "Etc/UTC",
                            })}
                          </li>
                        </ul>
                      }
                    >
                      <InfoIcon />
                    </Tooltip>
                  </Box>
                )}
                <TextField
                  autoComplete="off"
                  error={!!values.ecotaxe.error}
                  helperText={t(values.ecotaxe.error ?? "")}
                  sx={{ width: "100%" }}
                  type="text"
                  value={values.ecotaxe.value}
                  onChange={handleChange("ecotaxe")}
                />
              </TableCell>
              <TableCell
                sx={{
                  verticalAlign: "bottom",
                  ...(showBottom && {
                    borderBottom: "2px " + color + " dashed",
                  }),
                }}
              >
                {Number(fDocligne.sorecop) !==
                  Number(enteringInvoice?.sorecop ?? 0) && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {Number(fDocligne.sorecop)}
                    <ArrowForwardIcon />
                    {Number(enteringInvoice?.sorecop ?? 0)}
                    <Tooltip
                      title={
                        <ul>
                          <li>{enteringInvoice!.doPiece}</li>
                          <li>
                            {new Date(
                              enteringInvoice!.cbModification
                            ).toLocaleDateString(i18n.language, {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                              timeZone: "Etc/UTC",
                            })}
                          </li>
                        </ul>
                      }
                    >
                      <InfoIcon />
                    </Tooltip>
                  </Box>
                )}
                <TextField
                  autoComplete="off"
                  error={!!values.sorecop.error}
                  helperText={t(values.sorecop.error ?? "")}
                  sx={{ width: "100%" }}
                  type="text"
                  value={values.sorecop.value}
                  onChange={handleChange("sorecop")}
                />
              </TableCell>
              <TableCell
                sx={{
                  ...(showBottom && {
                    borderBottom: "2px " + color + " dashed",
                  }),
                }}
              >
                {Number(fDocligne.dlPrixunitaire)}
                <TextField
                  autoComplete="off"
                  error={!!values.dlPrixunitaire.error}
                  helperText={t(values.dlPrixunitaire.error ?? "")}
                  sx={{ width: "100%" }}
                  type="text"
                  value={values.dlPrixunitaire.value}
                  onChange={handleChange("dlPrixunitaire")}
                />
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "2px " + color + " solid",
                  ...(showBottom && {
                    borderBottom: "2px " + color + " dashed",
                  }),
                }}
              >
                {Number(fDocligne.dlMontantht)}
              </TableCell>
            </>
          ) : (
            <>
              <TableCell
                colSpan={10}
                sx={{
                  borderLeft: "2px " + color + " solid",
                  borderRight: "2px " + color + " solid",
                  ...(showBottom && {
                    borderBottom: "2px " + color + " dashed",
                  }),
                }}
              >
                {fDocligne.dlDesign}
              </TableCell>
            </>
          )}
        </TableRow>
      );
    }
  )
);

const EnteringInvoiceFDocenteteComponent: React.FC<State> = React.memo(
  ({ doPiece }) => {
    const [fDocentete, setFDocentete] = React.useState<
      FDocenteteInterface | undefined
    >(undefined);
    const getFDoclignesRef = React.useCallback(() => {
      const result = fDocentete?.fDoclignes?.map((f) => {
        return {
          fDocligne: f,
          ref: React.createRef(),
        };
      });
      result?.sort((a, b) => {
        if (a.fDocligne.dlTrempied !== b.fDocligne.dlTrempied) {
          return (
            (a?.fDocligne?.dlTrempied ?? 0) - (b?.fDocligne?.dlTrempied ?? 0)
          );
        }
        return (a.fDocligne.dlLigne ?? 0) - (b.fDocligne.dlLigne ?? 0);
      });
      return result;
    }, [fDocentete?.fDoclignes]);
    const [fDoclignesRef, setFDoclignesRef] = React.useState<
      FDocenteteLigneRefInterface[] | undefined
    >(getFDoclignesRef());
    const [loading, setLoading] = React.useState<boolean>(false);
    const [enteringInvoices, setEnteringInvoices] = React.useState<
      EnteringInvoiceInterface[] | undefined
    >(undefined);
    const refreshPage = useAppSelector(
      (state: RootState) => state.globalState.refreshPage
    );
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const dispatch = useAppDispatch();
    const [init, setInit] = React.useState(false);
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const fournisseurs = useAppSelector(
      (state: RootState) => state.globalState.fournisseurs
    );

    const load = React.useCallback(
      async (force: boolean = false) => {
        setInit(true);
        if (!force && fDocentete !== undefined) {
          dispatch(set({ refreshPage: false }));
          return;
        }
        if (force) {
          setFDocentete(undefined);
          setEnteringInvoices(undefined);
        }
        let nbDone = 0;
        requestApi({
          method: GET,
          path: FDOCENTETES_URL + "/" + doPiece,
          allowError: true,
          token: token,
        })
          .then((response) => {
            if (response.statusCode === 200) {
              setFDocentete(response.content);
            } else if (response.statusCode === 401) {
              toastr.info(t("word.info"), t("error.reconnect"));
            }
          })
          .catch(() => {
            toastr.error(t("word.error"), t("error.tryAgain"));
          })
          .finally(() => {
            nbDone++;
            if (nbDone === 2) {
              dispatch(set({ refreshPage: false }));
            }
          });
        requestApi({
          method: GET,
          path: FDOCENTETES_URL + "/" + doPiece + "/entering-invoice",
          allowError: true,
          token: token,
        })
          .then((response) => {
            if (response.statusCode === 200) {
              setEnteringInvoices(response.content);
            } else if (response.statusCode === 401) {
              toastr.info(t("word.info"), t("error.reconnect"));
            }
          })
          .catch(() => {
            toastr.error(t("word.error"), t("error.tryAgain"));
          })
          .finally(() => {
            nbDone++;
            if (nbDone === 2) {
              dispatch(set({ refreshPage: false }));
            }
          });
      },
      [dispatch, doPiece, fDocentete, t, token]
    );

    const save = React.useCallback(async () => {
      setLoading(true);
      const body = fDoclignesRef?.map((fDocligneRef) => {
        return fDocligneRef.ref.current.getValue();
      });
      const response = await requestApi({
        method: POST,
        path:
          FDOCENTETES_URL +
          "/" +
          fDocentete?.doType +
          "/" +
          fDocentete?.doPiece +
          "/entering-invoice",
        allowError: true,
        token: token,
        body: body,
      });
      if (response.statusCode === 200) {
        dispatch(set({ refreshPage: true }));
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    }, [
      dispatch,
      fDocentete?.doPiece,
      fDocentete?.doType,
      fDoclignesRef,
      t,
      token,
    ]);

    React.useEffect(() => {
      load();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (init && refreshPage) {
        load(true);
      }
    }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (init && !refreshPage) {
        dispatch(set({ refreshPage: true }));
      }
    }, [doPiece]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setFDoclignesRef(getFDoclignesRef());
    }, [fDocentete?.fDoclignes]); // eslint-disable-line react-hooks/exhaustive-deps

    const thisFournisseur = fournisseurs?.find(
      (f) => f.userIdentifier === fDocentete?.doTiers?.ctNum
    );
    let color = "black";
    if (
      fDocentete?.doType &&
      FDOCENETETE_COLORS.hasOwnProperty(fDocentete.doType)
    ) {
      color = FDOCENETETE_COLORS[fDocentete?.doType].main;
    }

    return (
      <>
        {fDocentete ? (
          <Table sx={{ width: "auto", marginY: 1 }}>
            <TableHead>
              <TableRow>
                <TableCell
                  colSpan={10}
                  sx={{
                    border: "2px " + color + " solid",
                  }}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      style={{
                        color: theme.palette.primary.main,
                        fontWeight: "bold",
                      }}
                    >
                      {thisFournisseur?.ctIntitule}{" "}
                      <CopyClipboardComponent
                        className="RobotoMono"
                        component="span"
                        text={fDocentete?.doTiers?.ctNum ?? ""}
                        sx={{ wordBreak: "keep-all" }}
                      />
                    </span>
                    <CopyClipboardComponent
                      className="RobotoMono"
                      component="span"
                      text={fDocentete.doPiece}
                      sx={{ wordBreak: "keep-all" }}
                    />
                    <span>
                      {t("word.doStatut.label") + ": "}
                      <span
                        style={{
                          fontWeight: "bold",
                          color: theme.palette.primary.main,
                        }}
                      >
                        {fDocentete.doStatutString}
                      </span>
                    </span>
                  </Typography>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            paddingY: 0,
                          }}
                        >
                          {t("word.doDatelivr")}
                        </TableCell>
                        <TableCell sx={{ paddingY: 0 }}>
                          {t("word.doRef")}
                        </TableCell>
                        <TableCell sx={{ paddingY: 0 }}>
                          {t("word.groupage")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ paddingY: 0 }}>
                          {new Date(fDocentete.doDatelivr).toLocaleString(
                            i18n.language,
                            {
                              dateStyle: "long",
                              timeZone: "America/Cuiaba",
                            }
                          )}
                        </TableCell>
                        <TableCell sx={{ paddingY: 0 }}>
                          {fDocentete.doRef}
                        </TableCell>
                        <TableCell sx={{ paddingY: 0 }}>
                          {fDocentete.doCoord01} {/*groupage*/}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    paddingY: 0,
                    borderLeft: "2px " + color + " solid",
                  }}
                >
                  {t("word.image")}
                </TableCell>
                <TableCell>{t("field.fArticleId")}</TableCell>
                <TableCell>{t("field.arDesign")}</TableCell>
                <TableCell>{t("word.refSupplier")}</TableCell>
                <TableCell>{t("word.quantity")}</TableCell>
                <TableCell>{"p.achat"}</TableCell>
                <TableCell>{"ecotaxe"}</TableCell>
                <TableCell>{"sorecop"}</TableCell>
                <TableCell>{"P.U. HT"}</TableCell>
                <TableCell sx={{ borderRight: "2px " + color + " solid" }}>
                  {"Montant HT"}
                </TableCell>
              </TableRow>
              {fDoclignesRef?.map((fDocligneRef, indexFDocligneRef) => {
                const showBottom =
                  indexFDocligneRef === fDoclignesRef!.length - 1;
                return (
                  <EnteringInvoiceFDocligneComponent
                    key={indexFDocligneRef}
                    fDocentete={fDocentete}
                    ref={fDocligneRef.ref}
                    fDocligne={fDocligneRef.fDocligne}
                    enteringInvoice={enteringInvoices?.find(
                      (e) => e?.arRef === fDocligneRef.fDocligne.fArticle?.arRef
                    )}
                    showBottom={showBottom}
                  />
                );
              })}
              <TableRow>
                <TableCell
                  colSpan={5}
                  sx={{
                    paddingY: 0,
                    borderLeft: "2px " + color + " solid",
                    borderBottom: "2px " + color + " solid",
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    borderBottom: "2px " + color + " solid",
                  }}
                >
                  <LoadingButton
                    variant="contained"
                    disabled={enteringInvoices === undefined || loading}
                    onClick={() => {
                      if (!fDoclignesRef) {
                        return;
                      }
                      for (const fDocligneRef of fDoclignesRef) {
                        fDocligneRef.ref.current.copyPuht();
                      }
                    }}
                    loading={false}
                  >
                    {t("word.copyPuht")}
                  </LoadingButton>
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "2px " + color + " solid",
                  }}
                >
                  <LoadingButton
                    variant="contained"
                    disabled={enteringInvoices === undefined || loading}
                    onClick={() => {
                      if (!fDoclignesRef) {
                        return;
                      }
                      for (const fDocligneRef of fDoclignesRef) {
                        fDocligneRef.ref.current.copyEcotaxe();
                      }
                    }}
                    loading={false}
                  >
                    {t("word.copyEcotaxe")}
                  </LoadingButton>
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "2px " + color + " solid",
                  }}
                >
                  <LoadingButton
                    variant="contained"
                    disabled={enteringInvoices === undefined || loading}
                    onClick={() => {
                      if (!fDoclignesRef) {
                        return;
                      }
                      for (const fDocligneRef of fDoclignesRef) {
                        fDocligneRef.ref.current.copySorecop();
                      }
                    }}
                    loading={false}
                  >
                    {t("word.copySorecop")}
                  </LoadingButton>
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "2px " + color + " solid",
                  }}
                >
                  <LoadingButton
                    variant="contained"
                    disabled={enteringInvoices === undefined || loading}
                    onClick={() => {
                      if (!fDoclignesRef) {
                        return;
                      }
                      for (const fDocligneRef of fDoclignesRef) {
                        fDocligneRef.ref.current.calculate();
                      }
                    }}
                    loading={false}
                  >
                    {t("word.calculate")}
                  </LoadingButton>
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "2px " + color + " solid",
                    borderBottom: "2px " + color + " solid",
                  }}
                >
                  <LoadingButton
                    variant="contained"
                    disabled={enteringInvoices === undefined}
                    onClick={save}
                    loading={loading}
                  >
                    {t("word.save")}
                  </LoadingButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={8}
                  sx={{
                    paddingY: 0,
                    borderLeft: "2px " + color + " solid",
                    borderBottom: "2px " + color + " solid",
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    borderBottom: "2px " + color + " solid",
                  }}
                >
                  {t("word.totalHt")}: {Number(fDocentete.doTotalhtnet)}
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "2px " + color + " solid",
                    borderBottom: "2px " + color + " solid",
                  }}
                >
                  {t("word.totalTtc")}: {Number(fDocentete.doTotalttc)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ) : (
          <SearchComponent nbColumn={1} nbLines={1} />
        )}
      </>
    );
  }
);

export default EnteringInvoiceFDocenteteComponent;
